.icon,
:global(.icon) {
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-success:before {
  content: "\e900";
}
.icon-star-empty:before {
  content: "\e901";
}
.icon-star:before {
  content: "\e902";
}
.icon-error:before {
  content: "\e903";
}
.icon-upload:before {
  content: "\e904";
}
.icon-support:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-record:before {
  content: "\e907";
}
.icon-phone:before {
  content: "\e908";
}
.icon-list:before {
  content: "\e909";
}
.icon-eye:before {
  content: "\e90a";
}
.icon-email:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-dependents:before {
  content: "\e90d";
}
.icon-delete:before {
  content: "\e90e";
}
.icon-datepicker:before {
  content: "\e90f";
}
.icon-dashboard:before {
  content: "\e910";
}
.icon-contact-phone:before {
  content: "\e911";
}
.icon-close:before {
  content: "\e912";
}
.icon-chat:before {
  content: "\e913";
}
.icon-arrow-right:before {
  content: "\e914";
}
.icon-arrow-left:before {
  content: "\e915";
}
.icon-arrow-down:before {
  content: "\e916";
}