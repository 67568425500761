/* footer css */
.footer_items{
    float: left;
    width: 33.33%;
  }
  .footer_items ul{
    padding-left: 0px;
    list-style: none;
  }
  .footer_logo {
    width: 300px;
  }
  .footer_constant{
    border-top: solid 1px #ccc;
    padding-top: 25px;
  }
  .fot_txt {
    font-weight: 700;
    font-size: 20px;
  }
  .footer_btm_txt{
    width: 100%;
    text-align: center;
    clear: both;
    font-size: 20px;
  }
  .footer_items ul li a{
    color: #333;
  }
  .footer_items ul li a:hover{
    text-decoration: none;
  }
  /* footer css end */