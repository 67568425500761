@import 'assets/styles/scss/variables.scss';

.root {
}

.upload {
  min-height: 190px;
  border-radius: 10px;
  border: 4px solid #acceed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  line-height: 36px;
  text-transform: none;
  background: #d4f1ff;
  cursor: pointer;
  &:hover {
    .browse {
      text-decoration: none;
    }
  }
  .fileheading {
    text-transform: capitalize;
    font-weight: 600 !important;
    font-size: 20px !important;
  }
}

.browse {
  color: #3663ed;
  text-decoration: none;
  text-transform: lowercase;
}

.upload-icon {
  font-size: 64px;
  margin-top: 16px;
}

.error {
  margin-top: 14px;
  font-size: 16px;
  background-color: #e1554f;
  border-radius: 8px;
  padding: 14px 0;
  color: #fff;
  text-align: center;
  span {
    font-weight: 700;
  }
}

.upload-item {
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
  border-radius: 0px;
  overflow: hidden;
  font-size: 15px;
  position: relative;
  border-bottom: solid 1px #ccc;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icons {
  position: absolute;
  top: 37px;
  right: 31px;
  font-size: 32px;
  display: flex;
  i {
    cursor: pointer;
    display: block;
    margin-left: 20px;
    color: $primary;
  }
}

.progress-value {
  color: #3663ed;
  font-size: 32px;
  margin-right: 19px;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(90, 126, 238, 0.08);
}
