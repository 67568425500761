@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
}
h2, h1, .montserrat{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
div{
  display: block;
}
.top_head {
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1265px) {
  .container {
      max-width: 1425px !important;
      width: inherit;
      margin: auto;
  }
}
.img-responsive{
  max-width: 100%;
}
.head_section {
  background: url('assets/images/Banner_BG.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 630px;
  padding-top: 30px;
}
.top_head_left {
  float: left;
  width: 300px;
}
.top_head_left > img{
  cursor: pointer;
}
.top_head_right {
  float: right;
}
span.rightmenu {
  display: inline-block;
  color: #fff;
  margin-right: 20px;
  font-size: 18px;
  padding:5px 10px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
.key_upload_code{
  border: solid 1px #fff;
}
.key_upload_record {
  border: solid 1px #ff6600;
  background: #ff6600;
}
.up_txt_box_constant {
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
}
.up_txt_box_constant .text-box {
  width: 300px;
  height: 60px;
  border: none;
  box-shadow: 2px 2px 2px 2px#e6e6e6;
  border-radius: 4px;
  margin-top: 15px;
  font-weight: 700;
  padding: 0 20px;
  text-align: center;
  font-size: 25px;
}
.display-flex {
  display: flex;
}
.upload_constant {
  text-align: center;
}
.main_wrapper {
  min-height: 750px;
  margin-top: -470px;
}
.up_txt_box_constant div:nth-child(3){
  /*margin-right: 30px;*/
}
.custom_width{
  width: 70% !important;
}
.drop_file_wrapper > div{
  display: inline-block;
  width: 20%;
  padding: 10px;
  vertical-align: top;
}
.upload-medical-wrapper {
  width: 650px;
  background: #f5f5ff;
  margin: auto;
  padding: 60px;
  border-radius: 30px;
  position: relative;
  margin-top: 100px;
  overflow: hidden;
}
.banner_left  h2.montserrat {
  color: #fff;
  font-size: 67px;
  font-weight: 700;
}
.banner_left h3{
  color: #fff;
  font-size: 27px;
}
.search_box input {
  font-size: 20px;
  color: #818087;
  font-weight: 400;
  width: 470px;
  padding: 10px 20px;
}
span.get_search {
  font-size: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  background: #4656d6;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: middle;
}
.search_box{
  display: inline-block;
  vertical-align: middle;
}
.key_med_txt {
    font-size: 31px;
    font-weight: 700;
}
.key_med_patients {
  display: inline-block;
  padding: 10px 15px;
  background: #f4f5ff;
  border-radius: 5px;
  vertical-align: middle;
}
.key_med_providers {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  border-radius: 5px;
  border: solid 1px #ccc;
  padding: 10px 15px;
}
.key_med_providers .key_med_txt{
  color: #2466cb;
}
.key_medical_for_wrapper h2{
  font-size: 20px;
  font-weight: 700;
}
.key_consolidate_wrapper, .key_manage_fam_wrapper, .key_sec_provider{
  width: 100%;
  margin-top: 50px;
}
.key_custom_left, .key_custom_right, .key_custom_left, .key_medical_right{
  display: inline-block;
  width: 49%;
  vertical-align: middle;
}
.key_custom_left h2, .key_medical_right h2{
  font-size: 45px;
}
.key_med_providers p, .key_med_patients p{
  font-weight: 600;
}
.key_custom_left p, .key_medical_right p{
  font-size: 25px;
}
span.key_get_rec {
  background: #024fc3;
  color: #fff;
  font-size: 22px;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 30px;
  margin-top: 20px;
  display: inline-block;
}
.key_medical_right{
  padding-left: 10px;
}
.key_sec_provider h2{
  font-size: 40px;
}
.key_sec_provider  p {
  font-size: 25px;
  width: 67%;
  margin: 30px auto;
}
.secure_items {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  text-align: center;
  font-size: 21px;
}
.secure_items .medical, .medical_item_icons{
  color: #125ac7;
}
.dicom, .dicom_item_icons{
  color: #00c48c;
}
.sec_item_wrapper {
  margin: 50px auto;
}
h2.how_work_txt, .key_pr_txt {
  font-size: 65px;
  text-align: center;
  margin-bottom: 55px;
}
.sec_main_wrapper, .key_pr_info_wrapper{
  background: #f4f5ff;
  margin: 50px auto;
  padding-bottom: 80px;
  padding-top: 30px;
}
.hw_item_txt {
  font-size: 37px;
  font-weight: 700;
  margin-top: 30px;
}
.key_hw_items p {
  font-size: 27px;
  font-weight: 600;
  width: 79%;
  margin: auto;
}
.key_hw_items {
  text-align: center;
  width: 33.33%;
  float: left;
}
.hw_img_wrapper img{
  margin: auto;
}

.key_pr_help_txt {
  text-align: center;
  font-size: 29px;
  font-weight: 500;
  margin: 30px auto;
}
.key_info_text h3{
  font-size: 37px;
  font-weight: 600;
}
.key_info_text p{
  font-size: 27px;
  font-weight: 500;
}
.key_pr_info_items {
  float: left;
  width: 50%;
  padding: 0px 15px;
}
.key_info_text {
  display: inline-block;
  vertical-align: middle;
  padding: 20px;
  width: 80%;
}
.key_info-icons {
  display: inline-block;
  vertical-align: middle;
}
.key_mobile_records{
  background: url('./assets/images/ContentImage_4_BG.png');
  padding-bottom: 150px;
}
.key-mob-rec_right {
  display: inline-block;
  width: 49%;
  vertical-align: middle;
  color: #fff;
  padding: 0px 20px;
}
.key-mob-rec_left {
  display: inline-block;
  width: 49%;
  vertical-align: middle;
}

.key-mob-rec_right h2 {
  font-size: 65px;
}
.key-mob-rec_right p {
  font-size: 20px;
  margin-top: 30px;
}
.health_wrapper{
  background: url('./assets/images/ContentImage_5.png');
}
.health_wrapper {
  background: #ff6600 url('./assets/images/ContentImage_5.png');
  background-position: right;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  padding: 60px 25px;
  margin-top: -100px;
}
.health_wrapper h2{
  font-size: 45px;
}
.health_wrapper p{
  font-size: 21px;
}
.get_started {
  display: inline-block;
  background: #0047b2;
  color: #fff;
  padding: 10px 30px;
  font-size: 22px;
  border-radius: 5px;
  margin-top: 23px;
  cursor: pointer;
}
.footer_items ul li{
  font-size: 20px;
}
span.key_learn_more {
    color: #024fc3;
    font-size: 21px;
    cursor: pointer;
}
span.check_icons {
  background: #024ec3;
  color: #fff;
  border: solid 5px #0fc592;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.check_icons i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.up_input_txt_wrapper .container > div {
  background-color: #d4f1fe !important;
  border-radius: 10px !important;
  margin: 10px 0px;
}
.up_input_txt_wrapper .container .fileheading{
  color: #444;
  font-weight: 700;
  font-size: 20px;
}
.up_input_txt_wrapper .container aside ul {
  background-color: #fff;
  border: solid 2px #f3cb4f;
  border-radius: 5px;
  margin-top: 30px;
  text-align: left;
  padding: 10px;
  position: relative;
}
.up_input_txt_wrapper .container aside ul{
  list-style: none;
  padding-left: 0px;
}
.up_input_txt_wrapper .container aside ul li{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.up_input_txt_wrapper .container aside ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: solid 2px #f3cb4f;
  padding: 20px 0px;
  padding-right: 40px;
  font-weight: 600;
}
.delete_icons {
  position: absolute;
  right: 4px;
  color: #ED210D;
  border-radius: 50%;
  top: 12px;
}
.danger_icons{
  position: absolute;
  left: 4px;
  color: #ED210D;
  top: 12px;
}
.up_act_btn {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}
span.browse_file {
    color: #0082f0;
    cursor: pointer;
}
p.drop_help_txt {
  margin-top: 20px;
  color: #444;
}
.drop_file_container {
  border: solid 5px #f8c82f;
  margin-top: 30px;
  border-radius: 10px;
}
p.fileheading {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .key_custom_left, .key_custom_right, .key_custom_left, .key_medical_right {
    display: block;
    width: 100%;
 }
 .key_hw_items,.key_pr_info_items{
   width: 100%;
 }
 .key_pr_info_items{
   text-align: center;
 }
 span.rightmenu{
   font-size: 15px;
 }
 .banner_left h2.montserrat, .key_custom_left h2, .key_medical_right h2, .key_sec_provider h2, h2.how_work_txt, .key_pr_txt, .hw_item_txt, .key-mob-rec_right h2, .health_wrapper h2{
   font-size: 22px;
 }
 .key_hw_items p{
   font-size: 18px;
 }
 .search_box input{
   width: 100%;
   margin-bottom: 10px;
 }
 .key_med_patients, .key-mob-rec_left, .key-mob-rec_right{
   width: 100%;
   margin-bottom: 20px;
 }
 img{
   max-width: 100%;
 }
 .search_box input{
   max-width: 100%;
 }
 span.key_get_rec, span.key_learn_more{
   display: block;
   text-align: center;
 }
 .sec_item_wrapper {
  width: 50%;
}
.secure_items{
  width:100%;
  text-align: left;
  margin-bottom: 20px;
}
.key_hw_items{
  margin: 10px 0px;
}
.footer_items{
  width: 100%;
  text-align: center;
}
.siteWrapper{
  text-align: center;
}
}
@media (max-width: 768px) {
  .top_head_left > img{
    padding: 0 10px;
  }
}
@media (max-width: 680px) {
  .upload-medical-wrapper{
    width: 100% !important;
  }
  .main_wrapper {
      padding: 0 15px;
  }
}
