@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.upload_img {
    background: #eae9f9;
    border-radius: 50%;
    margin: auto;
    width: 180px;
    height: 180px;
    position: relative;
}
.upload_img {
    background: #eae9f9;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    position: relative;
    margin: auto auto 15px;
}
.upload_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
p.up_txt {
    color: #ff6600;
    font-weight: 700;
    font-size: 45px;
}
p.up-help-txt {
    color: #262223;
    font-size:21px;
}
span.up_txt_box_constant {
    display: inline-block;
    margin-right: 25px;
}
input.text_box {
    width: 50px !important;
    margin-right: 10px;
    height: 60px;
    border: none;
    box-shadow: 2px 2px 2px 2px#e6e6e6;
    border-radius: 4px;
    margin-top: 15px;
}

span.up_txt_box_constant {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 30px;
}
button.app_btn {
    background: #024fc3;
    color: #fff;
    border: none;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
    font-size:21px;
}
button.app_btn:disabled {
    opacity: 0.5;
}
button.cancelBtn {
    background: #880b24;
}
.confirmationBtns {
    display: inline-flex;
}
.confirmationBtns:last-child {
    margin-left: 50px;
}
.footer__wrapper {
    margin-top: 50px;
}
/* footer css */
.footer_items{
    float: left;
    width: 33.33%;
  }
  .footer_items ul{
    padding-left: 0px;
    list-style: none;
  }
  .footer_logo {
    width: 300px;
  }
  .footer_constant{
    border-top: solid 1px #ccc;
    padding-top: 25px;
  }
  .fot_txt {
    font-weight: 700;
    font-size: 20px;
  }
  .footer_btm_txt{
    width: 100%;
    text-align: center;
    clear: both;
    font-size: 20px;
  }
  .footer_items ul li a{
    color: #333;
  }
  .footer_items ul li a:hover{
    text-decoration: none;
  }
  /* footer css end */
/*
** Flex Mixins **
*/
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 700;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

a:hover,
a:focus {
  text-decoration: none;
}

label.form-label {
  font-size: 14px;
  color: #212529;
}

input.form-control, select.form-control, textarea.form-control {
  height: 38px;
  font-size: 14px;
  color: #495057;
  padding: .375rem .75rem;
}

select.form-control {
  padding: .375rem .62rem;
}

body .p-growl {
  z-index: 999999 !important;
}

body .p-growl .p-growl-message-error .p-growl-image.pi-times::before {
  content: "\E922";
}

body .p-growl .p-growl-title {
  text-transform: capitalize;
}

body .p-component {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  margin-bottom: 0;
}

body .p-multiselect {
  border: 1px solid #ced4da;
}

body .p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}

body .p-multiselect .p-multiselect-label {
  padding: 0.54em 0.429em;
  padding-right: 2em;
}

body .filter-control,
body .p-multiselect {
  border-radius: 3px;
  height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

body .filter-control .p-multiselect-trigger,
body .p-multiselect .p-multiselect-trigger {
  background-color: transparent;
}

body .filter-row .filter-control {
  font-size: 14px;
}

body .filter-row .filter-control,
body .filter-row .p-multiselect {
  border-radius: 0;
}

button, a {
  outline: none;
}

button.app-btn, a.app-btn {
  background-color: #ef7824;
  border: 1px solid #ef7824;
  padding: 9px 14px;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
  min-width: 75px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 1.15;
  border-radius: 4px;
  text-transform: capitalize;
}

button.app-btn:hover, button.app-btn:focus, a.app-btn:hover, a.app-btn:focus {
  color: #ffffff;
}

button.btn.cell-btn, a.btn.cell-btn {
  padding: 0.3rem 0.4rem;
  font-size: 0.8rem;
  line-height: 1;
}

.btnOrange {
  padding: 7px 22px;
  font-family: montserrat,arial,verdana;
  font-size: 15px !important;
  letter-spacing: 0.5px;
  text-align: center;
  transition: .5s;
  background-size: 200% auto;
  box-shadow: 0 0 1.25em #eee;
  border-radius: 0;
  background-image: linear-gradient(90deg, #e5520c 0, #f8853a 51%, #e5520c);
  opacity: .85;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  -webkit-appearance: button;
  overflow: visible;
}

.btnOrange:hover, .btnOrange:focus {
  background-position: right center;
  text-decoration: none;
}

.error-message {
  width: 100%;
  margin-top: 0.35rem;
  font-size: 12px;
  color: #dc3545;
}

.flex-1 {
  flex: 1 1;
  background-color: whitesmoke;
  flex-wrap: nowrap;
}

.font-md {
  font-size: 16px !important;
}

.form-group .password-form-group {
  position: relative;
}

.form-group .password-btn {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 6px;
  padding: 0;
}

.inner-tab-wrapper {
  height: 100%;
}

.inner-tab-wrapper .nav-tabs {
  margin-left: 30px;
  border-bottom: none;
}

@media (max-width: 768px) {
  .inner-tab-wrapper .nav-tabs {
    margin-left: 20px;
  }
}

.inner-tab-wrapper .nav-tabs .nav-link {
  min-width: 162px;
  padding: 11px 30px;
  background: #d4e4f1;
  border-radius: 10px 10px 0 0;
  color: #70a2cb;
  text-align: center;
  z-index: 1;
  margin-bottom: 0px;
  font-size: 13px;
}

@media (max-width: 465px) {
  .inner-tab-wrapper .nav-tabs .nav-link {
    min-width: 130px;
    margin-left: 5px;
  }
}

.inner-tab-wrapper .nav-tabs .nav-link.active {
  color: #0165B9;
  border: none;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0px -4px 6px -1px #dfe2e4;
  letter-spacing: 0px;
}

.inner-tab-wrapper .nav-tabs .nav-link:not(:first-child) {
  margin-left: 8px;
}

.inner-tab-wrapper .tab-content {
  background: #ffffff;
  border-radius: 5px;
  display: block;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.inner-tab-wrapper .tab-content .active {
  display: -ms-grid;
  display: grid;
}

#noanim-tab-example-tabpane-members .striped-table thead th, #noanim-tab-example-tabpane-invoice .striped-table thead th, tbody td {
  color: #9ba9b4;
  border-bottom: 10px solid #f0f4f8;
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.centre-white {
  text-align: center;
  margin: 20px 0px 20px 0px;
}

.modal-header {
  background: #007bff !important;
}

.modal-title {
  color: #fff !important;
}

.modal-footer {
  border-top: none !important;
}

.modal-content {
  background: #fff !important;
}

.modal-body {
  padding: 2rem 1rem !important;
}

.nw_cusbtn {
  background: #fff !important;
  color: #ef7824 !important;
}

.nw_cusbtn:hover {
  background: #fff !important;
  color: #0069d9 !important;
  border: solid 1px #0069d9 !important;
}
/*# sourceMappingURL=base.css.map */

.recaptcha-form > div > div {
  display: flex;
  place-content: center;
  transform: scale(0.84); 
}

.dropzone-image {
    width:100px;
}
.dropzone_upload__JHVjk{
    min-height: 228px !important;
}
.dropzone-upload-item{
    margin: 5px;
    border-bottom: none !important;
    padding: 15px 24px !important;
}
.errorFile {
    background: #FFE4E7;
    color: #CD6660;
    border-style: dotted;
    border-radius: 7px !important;
    border-bottom: dotted !important;
}
.horizontal-line{
    border: solid 1px #f8c82f;
    margin: 0 5px;
}
@media (max-width: 1300px) {
    .drop_file_wrapper > div{
        width: 33% !important;
    }
}
@media (max-width: 886px) {
    .drop_file_wrapper > div{
        width: 50% !important;
    }
}

@media (max-width: 600px) {
    .drop_file_wrapper > div{
        width: 100% !important;
    }
}
.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000009c;
}
.loader-component {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.api-error-message {
    background-color: #F00D34;
    padding: 10px;
    border-radius: 6px;
}
.api-error-message > p {
    color: #ffffff;
}
.error_help_container {
    background: #f00e34;
    color: #fff;
    font-size: 21px;
    border-radius: 5px;
    padding: 8px;
}
.error_help_txt {
    display: inline-block;
    vertical-align: middle;
}
.error_icons {
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
}
.error_txt1{
    display: block;
}
span.error_txt2 {
    color: #ffc275;
}
.drop_file_wrapper{
    text-align: center;
    margin: auto;
}
.bg_white {
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
    top: 105px;
    bottom: 0;
}
h1.faq_tittle {
  color: #e06e28;
  text-align: center;
  margin-bottom: 20px;
}
.wrapper {
 margin: 0 auto;
}
.accordion-wrapper + * {
  margin-top: 0.5em;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border: solid 1px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}
.accordion-title:hover, .accordion-title.open {
  color: white;
  background: #1a50c3;
}
.accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}
.accordion-content {
  padding: 1em 1.5em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon_icon__2CbAA,.icon{font-family:'icon' !important;speak:none;font-style:normal;font-weight:normal;font-feature-settings:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.icon_icon-success__Z74yD:before{content:"\e900"}.icon_icon-star-empty__3k2AM:before{content:"\e901"}.icon_icon-star__1IIw_:before{content:"\e902"}.icon_icon-error__x8N01:before{content:"\e903"}.icon_icon-upload__cFTLf:before{content:"\e904"}.icon_icon-support__1uCgJ:before{content:"\e905"}.icon_icon-search__11uWY:before{content:"\e906"}.icon_icon-record__3f3c8:before{content:"\e907"}.icon_icon-phone__2Exj-:before{content:"\e908"}.icon_icon-list__3aoQp:before{content:"\e909"}.icon_icon-eye__1xfut:before{content:"\e90a"}.icon_icon-email__YTBh1:before{content:"\e90b"}.icon_icon-edit__2Rbkd:before{content:"\e90c"}.icon_icon-dependents__2PBt4:before{content:"\e90d"}.icon_icon-delete__2CwYd:before{content:"\e90e"}.icon_icon-datepicker__1pgz-:before{content:"\e90f"}.icon_icon-dashboard__2gT7_:before{content:"\e910"}.icon_icon-contact-phone__1IAvN:before{content:"\e911"}.icon_icon-close__WUP9j:before{content:"\e912"}.icon_icon-chat__hyrFd:before{content:"\e913"}.icon_icon-arrow-right__3D0RZ:before{content:"\e914"}.icon_icon-arrow-left__pATjK:before{content:"\e915"}.icon_icon-arrow-down__6ZoTo:before{content:"\e916"}

.dropzone_upload__JHVjk{min-height:190px;border-radius:10px;border:4px solid #acceed;display:flex;align-items:center;justify-content:center;flex-direction:column;font-size:15px;line-height:36px;text-transform:none;background:#d4f1ff;cursor:pointer}.dropzone_upload__JHVjk:hover .dropzone_browse__ePfAj{text-decoration:none}.dropzone_upload__JHVjk .dropzone_fileheading__1ha3O{text-transform:capitalize;font-weight:600 !important;font-size:20px !important}.dropzone_browse__ePfAj{color:#3663ed;text-decoration:none;text-transform:lowercase}.dropzone_upload-icon__3wHaE{font-size:64px;margin-top:16px}.dropzone_error__1YmGR{margin-top:14px;font-size:16px;background-color:#e1554f;border-radius:8px;padding:14px 0;color:#fff;text-align:center}.dropzone_error__1YmGR span{font-weight:700}.dropzone_upload-item__AnFIT{display:block;align-items:center;justify-content:space-between;padding:10px 24px;border-radius:0px;overflow:hidden;font-size:15px;position:relative;border-bottom:solid 1px #ccc;white-space:nowrap;text-overflow:ellipsis}.dropzone_icons__U9k7w{position:absolute;top:37px;right:31px;font-size:32px;display:flex}.dropzone_icons__U9k7w i{cursor:pointer;display:block;margin-left:20px;color:#3663ed}.dropzone_progress-value__5fe_c{color:#3663ed;font-size:32px;margin-right:19px}.dropzone_progress__2jSil{position:absolute;top:0;left:0;bottom:0;background-color:rgba(90,126,238,0.08)}

html, body{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
}
h2, h1, .montserrat{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
div{
  display: block;
}
.top_head {
  width: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1265px) {
  .container {
      max-width: 1425px !important;
      width: inherit;
      margin: auto;
  }
}
.img-responsive{
  max-width: 100%;
}
.head_section {
  background: url(/static/media/Banner_BG.f490e5c5.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 630px;
  padding-top: 30px;
}
.top_head_left {
  float: left;
  width: 300px;
}
.top_head_left > img{
  cursor: pointer;
}
.top_head_right {
  float: right;
}
span.rightmenu {
  display: inline-block;
  color: #fff;
  margin-right: 20px;
  font-size: 18px;
  padding:5px 10px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
.key_upload_code{
  border: solid 1px #fff;
}
.key_upload_record {
  border: solid 1px #ff6600;
  background: #ff6600;
}
.up_txt_box_constant {
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
}
.up_txt_box_constant .text-box {
  width: 300px;
  height: 60px;
  border: none;
  box-shadow: 2px 2px 2px 2px#e6e6e6;
  border-radius: 4px;
  margin-top: 15px;
  font-weight: 700;
  padding: 0 20px;
  text-align: center;
  font-size: 25px;
}
.display-flex {
  display: flex;
}
.upload_constant {
  text-align: center;
}
.main_wrapper {
  min-height: 750px;
  margin-top: -470px;
}
.up_txt_box_constant div:nth-child(3){
  /*margin-right: 30px;*/
}
.custom_width{
  width: 70% !important;
}
.drop_file_wrapper > div{
  display: inline-block;
  width: 20%;
  padding: 10px;
  vertical-align: top;
}
.upload-medical-wrapper {
  width: 650px;
  background: #f5f5ff;
  margin: auto;
  padding: 60px;
  border-radius: 30px;
  position: relative;
  margin-top: 100px;
  overflow: hidden;
}
.banner_left  h2.montserrat {
  color: #fff;
  font-size: 67px;
  font-weight: 700;
}
.banner_left h3{
  color: #fff;
  font-size: 27px;
}
.search_box input {
  font-size: 20px;
  color: #818087;
  font-weight: 400;
  width: 470px;
  padding: 10px 20px;
}
span.get_search {
  font-size: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 700;
  cursor: pointer;
  background: #4656d6;
  padding: 10px 15px;
  display: inline-block;
  vertical-align: middle;
}
.search_box{
  display: inline-block;
  vertical-align: middle;
}
.key_med_txt {
    font-size: 31px;
    font-weight: 700;
}
.key_med_patients {
  display: inline-block;
  padding: 10px 15px;
  background: #f4f5ff;
  border-radius: 5px;
  vertical-align: middle;
}
.key_med_providers {
  display: inline-block;
  vertical-align: middle;
  margin-left: 30px;
  border-radius: 5px;
  border: solid 1px #ccc;
  padding: 10px 15px;
}
.key_med_providers .key_med_txt{
  color: #2466cb;
}
.key_medical_for_wrapper h2{
  font-size: 20px;
  font-weight: 700;
}
.key_consolidate_wrapper, .key_manage_fam_wrapper, .key_sec_provider{
  width: 100%;
  margin-top: 50px;
}
.key_custom_left, .key_custom_right, .key_custom_left, .key_medical_right{
  display: inline-block;
  width: 49%;
  vertical-align: middle;
}
.key_custom_left h2, .key_medical_right h2{
  font-size: 45px;
}
.key_med_providers p, .key_med_patients p{
  font-weight: 600;
}
.key_custom_left p, .key_medical_right p{
  font-size: 25px;
}
span.key_get_rec {
  background: #024fc3;
  color: #fff;
  font-size: 22px;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 30px;
  margin-top: 20px;
  display: inline-block;
}
.key_medical_right{
  padding-left: 10px;
}
.key_sec_provider h2{
  font-size: 40px;
}
.key_sec_provider  p {
  font-size: 25px;
  width: 67%;
  margin: 30px auto;
}
.secure_items {
  display: inline-block;
  vertical-align: middle;
  width: 33%;
  text-align: center;
  font-size: 21px;
}
.secure_items .medical, .medical_item_icons{
  color: #125ac7;
}
.dicom, .dicom_item_icons{
  color: #00c48c;
}
.sec_item_wrapper {
  margin: 50px auto;
}
h2.how_work_txt, .key_pr_txt {
  font-size: 65px;
  text-align: center;
  margin-bottom: 55px;
}
.sec_main_wrapper, .key_pr_info_wrapper{
  background: #f4f5ff;
  margin: 50px auto;
  padding-bottom: 80px;
  padding-top: 30px;
}
.hw_item_txt {
  font-size: 37px;
  font-weight: 700;
  margin-top: 30px;
}
.key_hw_items p {
  font-size: 27px;
  font-weight: 600;
  width: 79%;
  margin: auto;
}
.key_hw_items {
  text-align: center;
  width: 33.33%;
  float: left;
}
.hw_img_wrapper img{
  margin: auto;
}

.key_pr_help_txt {
  text-align: center;
  font-size: 29px;
  font-weight: 500;
  margin: 30px auto;
}
.key_info_text h3{
  font-size: 37px;
  font-weight: 600;
}
.key_info_text p{
  font-size: 27px;
  font-weight: 500;
}
.key_pr_info_items {
  float: left;
  width: 50%;
  padding: 0px 15px;
}
.key_info_text {
  display: inline-block;
  vertical-align: middle;
  padding: 20px;
  width: 80%;
}
.key_info-icons {
  display: inline-block;
  vertical-align: middle;
}
.key_mobile_records{
  background: url(/static/media/ContentImage_4_BG.5c974efa.png);
  padding-bottom: 150px;
}
.key-mob-rec_right {
  display: inline-block;
  width: 49%;
  vertical-align: middle;
  color: #fff;
  padding: 0px 20px;
}
.key-mob-rec_left {
  display: inline-block;
  width: 49%;
  vertical-align: middle;
}

.key-mob-rec_right h2 {
  font-size: 65px;
}
.key-mob-rec_right p {
  font-size: 20px;
  margin-top: 30px;
}
.health_wrapper{
  background: url(/static/media/ContentImage_5.a1b2cb80.png);
}
.health_wrapper {
  background: #ff6600 url(/static/media/ContentImage_5.a1b2cb80.png);
  background-position: right;
  background-repeat: no-repeat;
  text-align: center;
  color: #fff;
  padding: 60px 25px;
  margin-top: -100px;
}
.health_wrapper h2{
  font-size: 45px;
}
.health_wrapper p{
  font-size: 21px;
}
.get_started {
  display: inline-block;
  background: #0047b2;
  color: #fff;
  padding: 10px 30px;
  font-size: 22px;
  border-radius: 5px;
  margin-top: 23px;
  cursor: pointer;
}
.footer_items ul li{
  font-size: 20px;
}
span.key_learn_more {
    color: #024fc3;
    font-size: 21px;
    cursor: pointer;
}
span.check_icons {
  background: #024ec3;
  color: #fff;
  border: solid 5px #0fc592;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.check_icons i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.up_input_txt_wrapper .container > div {
  background-color: #d4f1fe !important;
  border-radius: 10px !important;
  margin: 10px 0px;
}
.up_input_txt_wrapper .container .fileheading{
  color: #444;
  font-weight: 700;
  font-size: 20px;
}
.up_input_txt_wrapper .container aside ul {
  background-color: #fff;
  border: solid 2px #f3cb4f;
  border-radius: 5px;
  margin-top: 30px;
  text-align: left;
  padding: 10px;
  position: relative;
}
.up_input_txt_wrapper .container aside ul{
  list-style: none;
  padding-left: 0px;
}
.up_input_txt_wrapper .container aside ul li{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.up_input_txt_wrapper .container aside ul li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-bottom: solid 2px #f3cb4f;
  padding: 20px 0px;
  padding-right: 40px;
  font-weight: 600;
}
.delete_icons {
  position: absolute;
  right: 4px;
  color: #ED210D;
  border-radius: 50%;
  top: 12px;
}
.danger_icons{
  position: absolute;
  left: 4px;
  color: #ED210D;
  top: 12px;
}
.up_act_btn {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}
span.browse_file {
    color: #0082f0;
    cursor: pointer;
}
p.drop_help_txt {
  margin-top: 20px;
  color: #444;
}
.drop_file_container {
  border: solid 5px #f8c82f;
  margin-top: 30px;
  border-radius: 10px;
}
p.fileheading {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .key_custom_left, .key_custom_right, .key_custom_left, .key_medical_right {
    display: block;
    width: 100%;
 }
 .key_hw_items,.key_pr_info_items{
   width: 100%;
 }
 .key_pr_info_items{
   text-align: center;
 }
 span.rightmenu{
   font-size: 15px;
 }
 .banner_left h2.montserrat, .key_custom_left h2, .key_medical_right h2, .key_sec_provider h2, h2.how_work_txt, .key_pr_txt, .hw_item_txt, .key-mob-rec_right h2, .health_wrapper h2{
   font-size: 22px;
 }
 .key_hw_items p{
   font-size: 18px;
 }
 .search_box input{
   width: 100%;
   margin-bottom: 10px;
 }
 .key_med_patients, .key-mob-rec_left, .key-mob-rec_right{
   width: 100%;
   margin-bottom: 20px;
 }
 img{
   max-width: 100%;
 }
 .search_box input{
   max-width: 100%;
 }
 span.key_get_rec, span.key_learn_more{
   display: block;
   text-align: center;
 }
 .sec_item_wrapper {
  width: 50%;
}
.secure_items{
  width:100%;
  text-align: left;
  margin-bottom: 20px;
}
.key_hw_items{
  margin: 10px 0px;
}
.footer_items{
  width: 100%;
  text-align: center;
}
.siteWrapper{
  text-align: center;
}
}
@media (max-width: 768px) {
  .top_head_left > img{
    padding: 0 10px;
  }
}
@media (max-width: 680px) {
  .upload-medical-wrapper{
    width: 100% !important;
  }
  .main_wrapper {
      padding: 0 15px;
  }
}

