.api-error-message {
    background-color: #F00D34;
    padding: 10px;
    border-radius: 6px;
}
.api-error-message > p {
    color: #ffffff;
}
.error_help_container {
    background: #f00e34;
    color: #fff;
    font-size: 21px;
    border-radius: 5px;
    padding: 8px;
}
.error_help_txt {
    display: inline-block;
    vertical-align: middle;
}
.error_icons {
    display: inline-block;
    vertical-align: middle;
    padding-right: 20px;
}
.error_txt1{
    display: block;
}
span.error_txt2 {
    color: #ffc275;
}
.drop_file_wrapper{
    text-align: center;
    margin: auto;
}