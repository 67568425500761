
.upload_img {
    background: #eae9f9;
    border-radius: 50%;
    margin: auto;
    width: 180px;
    height: 180px;
    position: relative;
}
.upload_img {
    background: #eae9f9;
    border-radius: 50%;
    width: 180px;
    height: 180px;
    position: relative;
    margin: auto auto 15px;
}
.upload_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
p.up_txt {
    color: #ff6600;
    font-weight: 700;
    font-size: 45px;
}
p.up-help-txt {
    color: #262223;
    font-size:21px;
}
span.up_txt_box_constant {
    display: inline-block;
    margin-right: 25px;
}
input.text_box {
    width: 50px !important;
    margin-right: 10px;
    height: 60px;
    border: none;
    box-shadow: 2px 2px 2px 2px#e6e6e6;
    border-radius: 4px;
    margin-top: 15px;
}

span.up_txt_box_constant {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 30px;
}
button.app_btn {
    background: #024fc3;
    color: #fff;
    border: none;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
    font-size:21px;
}
button.app_btn:disabled {
    opacity: 0.5;
}
button.cancelBtn {
    background: #880b24;
}
.confirmationBtns {
    display: inline-flex;
}
.confirmationBtns:last-child {
    margin-left: 50px;
}
.footer__wrapper {
    margin-top: 50px;
}