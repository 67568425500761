.dropzone-image {
    width:100px;
}
.dropzone_upload__JHVjk{
    min-height: 228px !important;
}
.dropzone-upload-item{
    margin: 5px;
    border-bottom: none !important;
    padding: 15px 24px !important;
}
.errorFile {
    background: #FFE4E7;
    color: #CD6660;
    border-style: dotted;
    border-radius: 7px !important;
    border-bottom: dotted !important;
}
.horizontal-line{
    border: solid 1px #f8c82f;
    margin: 0 5px;
}
@media (max-width: 1300px) {
    .drop_file_wrapper > div{
        width: 33% !important;
    }
}
@media (max-width: 886px) {
    .drop_file_wrapper > div{
        width: 50% !important;
    }
}

@media (max-width: 600px) {
    .drop_file_wrapper > div{
        width: 100% !important;
    }
}