.bg_white {
    background: #fff;
    position: absolute;
    right: 0;
    left: 0;
    top: 105px;
    bottom: 0;
}
h1.faq_tittle {
  color: #e06e28;
  text-align: center;
  margin-bottom: 20px;
}
.wrapper {
 margin: 0 auto;
}
.accordion-wrapper + * {
  margin-top: 0.5em;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}
.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border: solid 1px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}
.accordion-title:hover, .accordion-title.open {
  color: white;
  background: #1a50c3;
}
.accordion-title.open::after {
  content: "";
  border-top: 0;
  border-bottom: 5px solid;
}
.accordion-content {
  padding: 1em 1.5em;
}
